import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import ScrollOut from "scroll-out"

import ArticleHeader from "../../../components/athena/articleHeader.js"
import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import Imgix from "react-imgix"

import styles from "./interview.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
}

const Reply = props => {
  const person = props.person
  const isQuestion = props.isQuestion

  return (
    <div className={styles.reply}>
      <div className={`${styles.replyPerson} serif-italic`}>{person}</div>
      <div
        className={`user-content-athena ${styles.replyContent} ${
          isQuestion ? "mono" : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  )
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta
  const coverImages = article.coverImages ? article.coverImages[0] : null

  // Assets
  const cutout1 = data.craft.cutout1[0]
  const cutout1Bg = data.craft.cutout1Bg[0]
  const cutout2 = data.craft.cutout2[0]
  const cutout2Bg = data.craft.cutout2Bg[0]
  const cutout3 = data.craft.cutout3[0]
  const cutout3Bg = data.craft.cutout3Bg[0]
  const cutout4 = data.craft.cutout4[0]
  const cutout4Bg = data.craft.cutout4Bg[0]

  // Initialize up ScrollOut library
  useEffect(() => {
    let so = ScrollOut({
      threshold: 0.1,
      cssProps: {
        viewportY: true,
      },
    })

    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            subtitle={article.articleSubTitle}
            coverImages={coverImages}
          />
          <div className={styles.articleBody}>
            <div className={styles.text}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet">
                    <div className="mb-8 user-content-athena">
                      <p>
                        What role do stories have in technology? And how do we
                        design technology with ethics in mind? We sat down with
                        human-computer interaction and ethics researcher{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.tue.nl/en/research/researchers/minha-lee/"
                        >
                          Minha Lee of Eindhoven University of Technology in the
                          Netherlands
                        </a>{" "}
                        to talk about the quickly evolving relationship between
                        humans and machines—with an emphasis on how machines can
                        be used to stoke humanity and build more dynamic
                        emotional lives. We talked with Lee about how artificial
                        agents can positively interact and strengthen the human
                        world.
                      </p>
                    </div>
                    <Reply person="Jason Zabel" isQuestion={true}>
                      <p>
                        You research the relationship between people and
                        machines. You’ve talked about using machines to create
                        narrative-centered designs for people. Can you say a bit
                        more about that, and why this is so important for
                        people?
                      </p>
                    </Reply>
                    <Reply person="Minha Lee">
                      <p>
                        We believe in real emotions in a fake world, but not
                        fake emotions in a real world. Often, well-crafted
                        stories contain characters with rich emotional lives
                        that we believe in. Conversely, when we feel that
                        someone’s emotional displays feel fake in our everyday
                        lives, we try to decipher and seek out their authentic
                        emotions and intentions. We search for what “feels real”
                        in artificial and everyday worlds because we are
                        storytelling, story-believing creatures.
                      </p>
                    </Reply>
                  </div>
                </div>
              </div>
            </div>
            <aside className={styles.pullquote}>
              <div className="wrapper">
                <div className="columns is-centered is-mobile">
                  <div className="column is-10">
                    <p className={styles.pullquoteText}>
                      We search for what{" "}
                      <span className="italic">feels real</span> in artificial
                      and everyday worlds because we are storytelling,
                      story-believing creatures.
                    </p>
                  </div>
                </div>
              </div>
            </aside>
            <div className={`${styles.text} ${styles.textPreBlob}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        While this might seem obvious, not enough care goes into
                        designing artificial agents that can reveal an emotional
                        inner world that we can believe in.{" "}
                        <span className={styles.highlight}>
                          Authenticity of emotions drive all narratives, be it
                          for our self-narrative or narratives of real or
                          imagined beings.
                        </span>{" "}
                        Phoebe Sengers, researcher at Cornell Tech, wrote about
                        how artificial agents need narrative intelligence
                        because “in a narrative, what actually happens matters
                        less than what the actors feel or think about what has
                        happened. Fundamentally, people want to know not just
                        what happened but why it happened.”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cutoutWrapper}>
              <div
                className={`${styles.cutout} ${styles.cutout1}`}
                data-scroll=""
              >
                <Imgix
                  className={styles.cutoutShapes}
                  width={cutout1.width}
                  height={cutout1.height}
                  sizes=""
                  src={cutout1.url}
                  htmlAttributes={{ alt: "" }}
                />
                <Imgix
                  className={styles.cutoutBg}
                  width={cutout1Bg.width}
                  height={cutout1Bg.height}
                  sizes=""
                  src={cutout1Bg.url}
                  htmlAttributes={{ alt: "" }}
                />
              </div>
            </div>
            <div className={`${styles.text} ${styles.textPostBlob}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet">
                    <Reply person="JZ" isQuestion={true}>
                      <p>
                        Technology as metaphor plays a big role in your work.
                        You’ve said that metaphor allows us to remove ourselves
                        from the problems we’re facing. Can you talk a bit about
                        the role of metaphor in your work, and how that relates
                        to machines?
                      </p>
                    </Reply>
                    <Reply person="ML">
                      <p>
                        Even at the UI level, we have metaphors like a “bin” for
                        discarding files we do not need with a literal icon of a
                        bin or a trash can. Beyond the interface, metaphors have
                        been part of how AI has been envisioned since the early
                        days (Sherry Turkle summarized this well in her 1995
                        book,{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.simonandschuster.com/books/Life-on-the-Screen/Sherry-Turkle/9780684833484"
                        >
                          Life on the Screen
                        </a>
                        ). There was the information processing model vs. the
                        emergent model of AI in 1960s. As the information
                        processing model became more prominent, metaphors on how
                        our intelligence can be likened to a computer became
                        normalized.
                      </p>
                      <p>
                        Take for example, “memory.” The metaphor that a computer
                        has “memory” does not alarm anyone these days. The
                        observation that a computer with “memory” can “process”
                        something sounds trivial because we are now accustomed
                        to talking about computers in that way. We forget that
                        the very language we use to describe how machines
                        “think” is metaphorical. Conversely, we are
                        uncomfortable with statements like: a computer with
                        “passion” can “feel” something. Metaphors involving
                        emotions feel too novel, too unlikely, at this point in
                        time.
                      </p>
                      <p>
                        And{" "}
                        <span className={styles.highlight}>
                          feelings seem like the one territory that truly
                          distinguishes us from machines, which is why people
                          may feel uncomfortable
                        </span>
                        , according to research we have been doing at Eindhoven
                        University of Technology. However, there can be an
                        alternative. Our project on{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://dl.acm.org/doi/abs/10.1145/3290605.3300932"
                        >
                          Vincent, a chatbot for self-compassion
                        </a>
                        , showed that Vincent and its fictional struggles as a
                        chatbot, e.g., being embarrassed about arriving late at
                        an IP address, were metaphorical reminders of common
                        human behaviors, with a fictional twist. We never
                        spoon-fed the metaphor like saying that a chatbot can
                        “feel embarrassed.” Through this “show not tell”
                        approach, we noticed that people told Vincent things
                        like “be proud of the chatbot that you are!” By saying
                        encouraging and even compassionate messages to a mere
                        bot, people’s self-compassion increased.{" "}
                        <span className={styles.highlight}>
                          Vincent is a metaphor to help us learn more about
                          human behavior, and about how people themselves want
                          to be treated.
                        </span>
                      </p>
                    </Reply>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cutoutWrapper}>
              <aside className={styles.cutoutQuote}>
                <p className={styles.cutoutQuoteText}>
                  Emotions have to be shown, not told.
                </p>
                <div className={styles.cutoutQuoteCutout}>
                  <div
                    className={`${styles.cutout} ${styles.cutout2}`}
                    data-scroll=""
                  >
                    <Imgix
                      className={styles.cutoutShapes}
                      width={cutout2.width}
                      height={cutout2.height}
                      sizes=""
                      src={cutout2.url}
                      htmlAttributes={{ alt: "" }}
                    />
                    <Imgix
                      className={styles.cutoutBg}
                      width={cutout2Bg.width}
                      height={cutout2Bg.height}
                      sizes=""
                      src={cutout2Bg.url}
                      htmlAttributes={{ alt: "" }}
                    />
                  </div>
                </div>
              </aside>
            </div>
            <div className={styles.text}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet">
                    <Reply person="JZ" isQuestion={true}>
                      <p>
                        Why do we humanize some machines? What causes us to do
                        that? How can technology become trustworthy?
                      </p>
                    </Reply>
                    <Reply person="ML">
                      <p>
                        We have been seeing faces in clouds for millennia. We
                        have seen faces of gods in nature and we prayed for good
                        harvest, rain and prosperity. We humanize things so that
                        they fit into our world so that we can make sense of
                        what we cannot understand. Yet all the complexity we
                        attribute to things, including machines, is a metaphor
                        of our own.
                      </p>
                      <p>
                        Herbert Simon, an AI scholar, has a story about an ant
                        crossing sand dunes. The ant looks like it knows where
                        it is going, moving with intention in its own
                        intelligent way. However, it is more that the
                        environment that the ant is crossing is complex (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://mitpress.mit.edu/books/sciences-artificial"
                        >
                          The Sciences of the Artificial
                        </a>
                        , 1969). The ant needs a landscape to appear
                        intelligent. Technology needs our humanity to appear
                        complex.
                      </p>
                      <p>
                        <span className={styles.highlight}>
                          Technology becomes worthy of our trust when we stop
                          seeing it as autonomously complex.
                        </span>{" "}
                        In some ways, this means taking back some of the easily
                        given trust, with a simple example of rethinking what it
                        means to consent to cookies online. It is more that we
                        are consenting to frictionless interaction, not giving
                        trust. We want to autonomously, seamlessly surf the web,
                        but we do not want technology to autonomously,
                        seamlessly surf our history as traces of our humanity
                        online.{" "}
                        <span className={styles.highlight}>
                          Overall, the concern is not that machines will become
                          too human-like, but that humans may become too
                          machine-like to notice
                        </span>{" "}
                        (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.penguinrandomhouse.com/books/313732/reclaiming-conversation-by-sherry-turkle/"
                        >
                          Reclaiming Conversation
                        </a>{" "}
                        by Sherry Turkle and{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.versobooks.com/books/3143-minima-moralia"
                        >
                          Minima Moralia
                        </a>{" "}
                        by Theodor Adorno). We should consider ways to reclaim
                        some of the autonomy and complexity of being human.
                      </p>
                    </Reply>
                  </div>
                </div>
              </div>
            </div>
            <aside className={styles.pullquote}>
              <div className="wrapper">
                <div className="columns is-centered is-mobile">
                  <div className="column is-11 is-10-tablet">
                    <p className={styles.pullquoteText}>
                      We humanize things so that they fit into our world so that
                      we can make sense of what we cannot understand.
                    </p>
                  </div>
                </div>
              </div>
            </aside>
            <div className={`${styles.text} ${styles.textPreBlob}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet">
                    <Reply person="JZ" isQuestion={true}>
                      <p>
                        You have a very positive outlook about the relationship
                        between machines and people. What considerations do you
                        think we need to keep top of mind to ensure machine
                        learning is good for people—not exploitive, or not a
                        replacement for our humanity?
                      </p>
                    </Reply>
                    <Reply person="ML">
                      <p>
                        <span className={styles.highlight}>
                          We can no longer expect people to understand what
                          their data is representing. Information used to be one
                          step removed from original data; now, with big data
                          that are required for machine learning to work, there
                          are too many levels of interpretation that people
                          don't know what to make sense of.
                        </span>{" "}
                        This means that, at a meta level, people have no
                        relationship at all to the data they are generating and
                        seeing we are often fed, e.g., the output of machine
                        learning, only.
                      </p>
                      <p>
                        With this, there are many unwanted consequences, like
                        when black men’s facial expressions are labeled as
                        “angry” more so than white men’s faces by the algorithm
                        (Lauren Rhue, 2018). When machines learn about gender or
                        race through computer vision, we lose internal factors
                        on who we identify as and we get labeled by computers in
                        a biased way (Scheuerman, M. K., Wade, K., Lustig, C., &
                        Brubaker, J. R., 2020).
                      </p>
                      <p>
                        <span className={styles.highlight}>
                          We get reduced, even dehumanized, by human-made
                          systems.
                        </span>{" "}
                        One option is to ban these machine learning systems
                        completely. Another path is to make sure that we
                        diversify our dataset. Yet the sobering catch-22 is
                        unhelpful. Either contribute to diversifying the dataset
                        when one prefers to opt out, or live with being
                        mislabeled.{" "}
                        <span className={styles.highlight}>
                          The consequence of being mislabeled can be
                          life-threatening, e.g., incarceration due to being
                          mislabeled as a threat, especially for marginalized
                          people.
                        </span>{" "}
                        I don’t have a solution for this problem. But the scope
                        of the problem for me is more micro-level,
                        conversational interactions than macro-level
                        interventions.
                      </p>
                    </Reply>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cutoutWrapper}>
              <div
                className={`${styles.cutout} ${styles.cutout3}`}
                data-scroll=""
              >
                <Imgix
                  className={styles.cutoutShapes}
                  width={cutout3.width}
                  height={cutout3.height}
                  sizes=""
                  src={cutout3.url}
                  htmlAttributes={{ alt: "" }}
                />
                <Imgix
                  className={styles.cutoutBg}
                  width={cutout3Bg.width}
                  height={cutout3Bg.height}
                  sizes=""
                  src={cutout3Bg.url}
                  htmlAttributes={{ alt: "" }}
                />
              </div>
            </div>
            <div className={`${styles.text} ${styles.textPostBlob}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet">
                    <Reply person="JZ" isQuestion={true}>
                      <p>
                        How can we move toward a world where technology is truly
                        more of an exploratory space for people? How can
                        technology help trigger us to reveal more aspects of who
                        we are? Where is this currently happening, or what’s
                        particularly interesting right now in this space?
                      </p>
                    </Reply>
                    <Reply person="ML">
                      <p>
                        There's research that shows people are more willing to
                        disclose sensitive information to a virtual therapist —
                        a fake machine — because they think it's not going to
                        judge them (Lucas, G. M., Gratch, J., King, A., &
                        Morency, L. P., 2014).{" "}
                        <span className={styles.highlight}>
                          People feel safer to try out different versions of who
                          they can be.
                        </span>{" "}
                        Technology can then be good because it disarms people
                        [in a safe way] at an interpersonal, micro-level of
                        interaction. It's harder for most people to be in the
                        care-receiving role because we want to show our
                        independence. We really want to show that we're not that
                        vulnerable, but vulnerable in a calculated way. Could we
                        see technology as the vulnerable other, especially if
                        people are afraid of appearing vulnerable to other
                        people?
                      </p>
                    </Reply>
                  </div>
                </div>
              </div>
            </div>
            <aside className={styles.pullquote}>
              <div className="wrapper">
                <div className="columns is-centered is-mobile">
                  <div className="column is-10">
                    <p className={styles.pullquoteText}>
                      Could we see technology as the vulnerable other,
                      especially if people are afraid of appearing vulnerable to
                      other people?
                    </p>
                  </div>
                </div>
              </div>
            </aside>
            <div className={`${styles.text} ${styles.textPreBlob}`}>
              <div className="wrapper">
                <div className="columns is-mobile is-centered">
                  <div className="column is-10-mobile is-10-tablet is-7-desktop">
                    <div className="user-content-athena">
                      <p>
                        A way to think about technologies that enter our lives
                        in a very intimate way (like Alexa listening to you
                        sleep) is to determine the exact point at which someone
                        or something is attributed a moral trait like
                        trustworthiness for us to be okay with being vulnerable.
                        Socially driven concepts like trust require interaction.
                        One cannot simply attribute trustworthiness to another
                        without the other having earned it in some way.
                        Technology has a chance to become this trustworthy agent
                        through interactions.{" "}
                        <span className={styles.highlight}>
                          Those humanized attributes of a thing, be it
                          trustworthiness or compassion, have no reason if
                          interactions do not exist.
                        </span>
                      </p>
                      <p>
                        Going forward, what will be interesting is dissecting
                        how we don't moralize technology in the same way [as we
                        moralize humans]. An example is that with technology,
                        people don't blame it, but are more willing to punish
                        it. There is a responsibility gap when we don't really
                        know who to blame. When too many people are accountable,
                        nobody is accountable. It's easier for people
                        emotionally and cognitively to find one entity to place
                        blame on, but because technology cannot understand
                        blame, perhaps punishment is the way blame is
                        distributed. That's how you take ownership of a negative
                        emotion you might have. In these ways,{" "}
                        <span className={styles.highlight}>
                          I am curious about how our social and moral rituals
                          will change through technology.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cutoutWrapper}>
              <div
                className={`${styles.cutout} ${styles.cutout4}`}
                data-scroll=""
              >
                <Imgix
                  className={styles.cutoutShapes}
                  width={cutout4.width}
                  height={cutout4.height}
                  sizes=""
                  src={cutout4.url}
                  htmlAttributes={{ alt: "" }}
                />
                <Imgix
                  className={styles.cutoutBg}
                  width={cutout4Bg.width}
                  height={cutout4Bg.height}
                  sizes=""
                  src={cutout4Bg.url}
                  htmlAttributes={{ alt: "" }}
                />
              </div>
            </div>
            <div className={styles.citations}>
              <div className="wrapper">
                <div className="columns is-mobile is-multiline is-centered">
                  <div
                    className={`column is-12-mobile is-10-tablet is-6-desktop ${styles.citationCol}`}
                  >
                    <div className="mb-6">
                      <h3 className="a-h-2 fw-light">Cited literature</h3>
                    </div>
                    <div
                      className={`user-content-athena ${styles.referenceList}`}
                    >
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.versobooks.com/books/3143-minima-moralia"
                          >
                            Minima Moralia: Reflections from Damaged Life
                          </a>{" "}
                          by Theodor W. Adorno (2005 [1951]).
                        </li>
                        <li>
                          People May Punish, But Not Blame Robots by Minha Lee,
                          Peter Ruijten, Lily Frank, Yvonne de Kort, and Wijnand
                          IJsselsteijn (2021).
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.sciencedirect.com/science/article/abs/pii/S0747563214002647"
                          >
                            It’s only a computer: Virtual humans increase
                            willingness to disclose
                          </a>{" "}
                          by Gale Lucas, Jonathan Gratch, Aisha King, and
                          Louis-Philippe Morency (2014).
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3281765"
                          >
                            Racial Influence on Automated Perceptions of
                            Emotions
                          </a>{" "}
                          by Lauren Rhue (2018).
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://dl.acm.org/doi/abs/10.1145/3392866"
                          >
                            How We've Taught Algorithms to See Identity:
                            Constructing Race and Gender in Image Databases for
                            Facial Analysis
                          </a>{" "}
                          by Morgan Klaus Scheuerman, Kandrea Wade, Caitlin
                          Lustig, and Jed R. Brubaker (2020).
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://benjamins.com/catalog/aicr.46"
                          >
                            Narrative Intelligence
                          </a>{" "}
                          by Phoebe Sengers. In K. Dautenhahn (Ed.), Human
                          Cognition and Social Agent Technology (2000).
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://mitpress.mit.edu/books/sciences-artificial"
                          >
                            The Sciences of the Artificial
                          </a>{" "}
                          by Herbert Simon (2019 [1969]).
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.simonandschuster.com/books/Life-on-the-Screen/Sherry-Turkle/9780684833484"
                          >
                            Life on the Screen
                          </a>{" "}
                          by Sherry Turkle (1995).
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.penguinrandomhouse.com/books/313732/reclaiming-conversation-by-sherry-turkle/"
                          >
                            Reclaiming Conversation: The Power of Talk in a
                            Digital Age Book
                          </a>{" "}
                          by Sherry Turkle
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="column is-12-mobile is-10-tablet is-6-desktop">
                    <div className="mb-6">
                      <h3 className="a-h-2 fw-light">Additional reading</h3>
                    </div>
                    <div
                      className={`user-content-athena ${styles.referenceList}`}
                    >
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://dl.acm.org/doi/abs/10.1145/3290605.3300932"
                          >
                            Caring for Vincent: A Chatbot for Self-compassion
                          </a>{" "}
                          by Minha Lee,Sander Ackermans, Nena van As, Hanwen
                          Chang, Enzo Lucas, and Wijnand IJsselsteijn
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://houseofanansi.com/products/the-real-world-of-technology-digital"
                          >
                            The Real World of Technology
                          </a>{" "}
                          by Ursula Franklin
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.versobooks.com/books/2505-psychopolitics"
                          >
                            Psychopolitics: Neoliberalism and New Technologies
                            of Power
                          </a>{" "}
                          by Byung-Chul Han
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.versobooks.com/books/2992-duty-free-art"
                          >
                            Duty Free Art Art in the Age of Planetary Civil War
                          </a>{" "}
                          by Hito Steyerl
                        </li>

                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.tandfonline.com/doi/abs/10.1207/S15327051HCI1523_5?casa_token=PdqddBRq2PoAAAAA%3A-2MJU_ua8AulTteGpY9KbwW6kXw2WLtYEJsaS0PkqJprNNwpGKuJmSsEQp9BYuebEH8zOx1SNAk&"
                          >
                            The Intellectual Challenge of CSCW: The Gap Between
                            Social Requirements and Technical Feasibility
                          </a>{" "}
                          by Mark S. Ackerman
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      cutout1: assets(id: 22635) {
        url
        width
        height
      }
      cutout1Bg: assets(id: 22634) {
        url
        width
        height
      }
      cutout2: assets(id: 22631) {
        url
        width
        height
      }
      cutout2Bg: assets(id: 22630) {
        url
        width
        height
      }
      cutout3: assets(id: 22632) {
        url
        width
        height
      }
      cutout3Bg: assets(id: 22629) {
        url
        width
        height
      }
      cutout4: assets(id: 22633) {
        url
        width
        height
      }
      cutout4Bg: assets(id: 22628) {
        url
        width
        height
      }
    }
  }
`
